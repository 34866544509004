<template>
  <div>
    <ExplorerSearchForm v-if="expanded" @toggleTimeout="toggleTimeout" />
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <LoadingHero v-if="timeout" />
    </transition>
    <ExplorerGroupConfirmation
      v-if="hasGroups"
      @expand="expanded = !expanded"
      v-bind="{ expanded }"
    />
    <ExplorerGroupTable v-if="!listings" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ExplorerSearch',
  data: () => ({ expanded: true, timeout: false }),
  components: {
    ExplorerSearchForm: () => import('./ExplorerSearchForm'),
    ExplorerGroupTable: () => import('./ExplorerGroupTable'),
    ExplorerGroupConfirmation: () => import('./ExplorerGroupConfirmation'),
    LoadingHero: () => import('./LoadingHero')
  },
  computed: {
    ...mapState('explorer', ['listings']),
    ...mapGetters('explorer', ['hasGroups'])
  },
  methods: {
    toggleTimeout(value) {
      this.timeout = value
    }
  }
}
</script>
